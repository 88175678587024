import yaml from 'js-yaml'
import { convertAppFieldsToDbProperties } from '../../utils/fieldConversion'
import * as XLSX from 'xlsx'

const getYaml = (selectedRecord) => {
  // Customize the YAML structure as needed
  const yamlData = {
    basic: {
      Region: selectedRecord.region,
      HUC: selectedRecord.huc,
      City: selectedRecord.city,
      County: selectedRecord.county,
      title: selectedRecord.title,
      description: selectedRecord.description,
      purpose: selectedRecord.purpose,
      creator: selectedRecord.creator,
      'tdis-identifier': selectedRecord.tdisIdentifier,
      'keyword-term': selectedRecord.keywordTerm,
      'update-frequency': selectedRecord.updateFrequency,
      'use-constraint': selectedRecord.useConstraint,
    },
    contact: {
      'contact-affiliation': selectedRecord.contactAffiliation,
      'contact-department': selectedRecord.contactDepartment,
      'contact-email': selectedRecord.contactEmail,
      'contact-name': selectedRecord.contactName,
      'contact-phone-number': selectedRecord.contactPhoneNumber,
    },
    spatial_information: {
      'spatial-data-type': selectedRecord.spatialDataType,
      'spatial-extent-decimal-degrees':
        selectedRecord.spatialExtentDecimalDegrees,
      'boundary-name': selectedRecord.boundaryName,
      'horizontal-coordinate-system': selectedRecord.horizontalCoordinateSystem,
    },
    model: {
      'model-software-name': selectedRecord.modelSoftwareName,
      'model-software-version': selectedRecord.modelSoftwareVersion,
      'model-type': selectedRecord.modelType,
      'model-input': selectedRecord.modelInput,
      'model-output': selectedRecord.modelOutput,
    },
  }

  return yaml.dump(yamlData)
}

export const useYMLDownload = () => {
  const getFile = (selectedRecord, recordId) => {
    const exportedFilename = `metadata_${recordId}.yml`
    var blob = new Blob(
      [
        getYaml(
          convertAppFieldsToDbProperties(
            selectedRecord.currentMetadata,
            recordId
          )
        ),
      ],
      { type: 'text/plain;charset=utf-8;' }
    )
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilename)
    } else {
      var link = document.createElement('a')
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }
  return getFile
}

export const useExcelDownload = () => {
  const getFile = (recordId) => {
    // Define the headers
    const headers = ['Input File Name', 'Output File Name', 'Other Files']

    // Create a new workbook and a worksheet
    const wb = XLSX.utils.book_new()
    const ws_data = [headers] // Add more data if necessary
    const ws = XLSX.utils.aoa_to_sheet(ws_data)

    // Append worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

    // Generate Excel file buffer
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })

    // Create a Blob from the buffer
    const blob = new Blob([wbout], { type: 'application/octet-stream' })

    // Define the file name
    const exportedFilename = `metadata_${recordId}.xlsx`

    // Trigger download
    if (navigator.msSaveBlob) {
      // For IE 10+
      navigator.msSaveBlob(blob, exportedFilename)
    } else {
      // For other browsers
      const link = document.createElement('a')
      if (link.download !== undefined) {
        // Feature detection
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }
  return getFile
}
