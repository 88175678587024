import { Component } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

// Wrapper to inject location into the class component
const ScrollToTopWrapper = (props) => {
  const location = useLocation();
  return <ScrollToTop {...props} location={location} />;
};

export default ScrollToTopWrapper;