import React from 'react'
import { createRoot } from 'react-dom/client'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import App from './App'
import './style/index.css'
import keycloak from './keycloak'

const root = createRoot(document.getElementById('root'))

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{
      onLoad: 'check-sso', // Change from 'login-required' to 'check-sso'
      silentCheckSsoRedirectUri:
        window.location.origin + '/silent-check-sso.html',
    }}
    onEvent={(event, error) => {
      console.log('Keycloak event:', event)
      if (event === 'onAuthLogout') {
        console.log('User logged out')
      }
      if (error) console.error('Keycloak error:', error)
    }}
    onTokens={(tokens) => {
      console.log('Keycloak tokens:', tokens)
    }}
  >
    <App />
    </ReactKeycloakProvider>
)
