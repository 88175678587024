import React, { useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useTable, usePagination, useFilters } from 'react-table'
import { useRequest } from 'redux-query-react'
import { recordsSelector } from '../selectors/entities'
import { Button, LinkLikeButton } from './Button'
import {
  ERROR,
  GREY_DARK,
  MAIN_CONTENT_WIDTH,
  FOSSIL,
  GREY_LIGHTER,
  GREY_MEDIUM,
  PRIMARY,
  SHADOW_CRISP,
  SLATE,
  SAGE,
  RAIN,
  NOTICE,
  SECONDARY,
  SHADOW_MEDIUM,
} from '../constants/cssVars'
import searchIcon from '../assets/icons/search.svg'
import { useNavigate } from 'react-router-dom'
import { Dropdown } from './general/Dropdown'
import {
  Table,
  TableRow,
  TableHeader,
  TableData,
  TableFooter,
  TableWrapper,
  OuterWrapper,
} from './Common'
import { metadataFieldInfo, metadataInit } from '../constants/metadataFields'
import cardIcon from '../assets/icons/cards.svg'
import tableIcon from '../assets/icons/table.svg'
import cardIconWhite from '../assets/icons/cards_white.svg'
import tableIconWhite from '../assets/icons/table_white.svg'
import { searchStrSelector, showTableViewSelector } from '../selectors/general'
import {
  setContactsAndRoles,
  setSearch,
  setTableView,
  updateContactId,
} from '../actions/general'
import {
  useDeleteRecordQuery,
  useDuplicateRecord,
} from '../hooks/messagesAndRequests'
import { useFilteredRecords } from '../hooks/useFilteredRecords'
import { UploadConflictsBanner } from './UploadConflictsBanner'
import { getAllUploadConflictsQuery, getRecordsQuery } from '../actions/queries'
import { WaveLoader } from './Loaders'
import { useCurrentUser } from '../hooks/useCurrentUser'
import { useInterval } from '../hooks/useInterval'
import { QUERY_REFRESH_INTERVAL_MS } from '../constants/general'
import { Link } from 'react-router-dom'
import { useGlobalFilter } from 'react-table/dist/react-table.development'

const SearchWrapper = styled.div`
  position: relative;
  width: 250px;
  > img {
    position: absolute;
    top: 8px;
    right: 10px;
    width: 20px;
    height: 20px;
  }
  > input {
    padding-right: 35px !important;
    font-size: 0.9em;
  }
`

const ActionsBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
`

const ViewAndSearch = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
`

const Tile = styled.div`
  margin-bottom: 20px;
  width: 100%;
  background-color: #fff;
  box-shadow: ${SHADOW_CRISP};
  border: 1px solid ${FOSSIL};
  text-align: left;
  transition: all 0.2s ease;
  > div {
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
  &:hover {
    box-shadow: ${SHADOW_MEDIUM};
  }
`

const TileHeader = styled.h3`
  margin: 0px;
  margin-bottom: 10px;
  color: ${PRIMARY};
`

const TileText = styled.p`
  margin: 0px;
  margin-bottom: 10px;
  color: ${SECONDARY};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  hyphens: auto;
`

const TableCardButtonWrapper = styled.div`
  padding-bottom: 0px;
  margin-right: 10px;
`

const TableButton = styled.button`
  border-radius: 0px 30px 30px 0px;
  padding: 5px 10px;
  padding-right: 15px;
  background-color: ${({ active }) => (active ? PRIMARY : '#fff')};
  border: 1px solid ${GREY_MEDIUM};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s linear;
  > img {
    width: 30px;
    height: 30px;
  }
  &:hover {
    background-color: ${({ active }) => (active ? PRIMARY : GREY_LIGHTER)};
  }
`

const CardButton = styled(TableButton)`
  border-radius: 30px 0px 0px 30px;
  padding-left: 15px;
  padding-right: 10px;
  border-right: 0px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${PRIMARY};
  > h3 {
    margin: 0px;
    margin-bottom: 20px;
  }
`

const TopContent = styled.div`
  width: ${MAIN_CONTENT_WIDTH}px;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    width: 100%;
  }
`

const ListWrapper = styled.div`
  width: ${MAIN_CONTENT_WIDTH}px;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    width: 100%;
  }
`

const CreateDesktop = styled.div`
  display: block;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    display: none;
  }
`

const CreateMobile = styled.div`
  display: none;
  margin-bottom: 10px;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    display: block;
  }
`

// Homepage display listing all of the metadata records in card or table view
export const AllMetadataRecords = () => {
  const search = useSelector(searchStrSelector)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { accessToken, username } = useCurrentUser()
  const [{ isFinished }] = useRequest(
    accessToken ? getRecordsQuery(username, accessToken) : null
  )

  const createButton = (
    <Button
      short={true}
      onClick={() => {
        navigate('/app/form/new')
        dispatch(setContactsAndRoles('new', [''], ['']))
      }}
    >
      Create +
    </Button>
  )

  return (
    <>
      <TopContent>
        <Header>
          <h3>Metadata Records</h3>
          <CreateMobile>{createButton}</CreateMobile>
        </Header>
        <ActionsBar>
          <ViewAndSearch>
            <SearchWrapper>
              <img src={searchIcon} alt="Search magnifying glass" />
              <input
                id="noticesSearchInput"
                placeholder="Search by name or ID"
                onChange={(e) => dispatch(setSearch(e.target.value))}
                value={search || ''}
              />
            </SearchWrapper>
          </ViewAndSearch>
          <CreateDesktop>{createButton}</CreateDesktop>
        </ActionsBar>
      </TopContent>
      <CardsView recordsQueryFinished={isFinished} />
    </>
  )
}

const statusColor = {
  'in progress': SLATE,
  pending: NOTICE,
  accepted: SAGE,
  rejected: ERROR,
}

const statusVerb = {
  'in progress': 'Last updated',
  pending: 'Uploaded',
  accepted: 'Uploaded',
  rejected: 'Uploaded',
}

const StatusCard = styled.div`
  border-radius: 40px;
  padding: 2px 10px;
  display: inline-block;
  background-color: ${({ status }) => statusColor[status]};
  color: #fff;
`

// If the metadata object has 'in progress' status, link to form - otherwise link to static viewer
// obj.statusInfo.status === 'in progress'
const getMetadataLink = (obj) => {
  return `/app/form/${obj.tdisIdentifier}`
}

const prettyPrintArray = (arr, suffix) =>
  Array.isArray(arr)
    ? arr.map((str) => (suffix ? `${str} ${suffix}` : str)).join(', ')
    : ''

// Display metadata records as a list of cards
const CardsView = ({ recordsQueryFinished }) => {
  const navigate = useNavigate()
  const records = useFilteredRecords()
  const [duplicate] = useDuplicateRecord()
  const [deleteRecord] = useDeleteRecordQuery()

  return (
    <ListWrapper>
      {recordsQueryFinished && records.length === 0 && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              maxWidth: '600px',
              textAlign: 'center',
              fontStyle: 'italic',
              color: GREY_DARK,
            }}
          >
            <p>No records found.</p>
            <p>
              Once you're ready to get started, click <b>"Create +"</b> in the
              top-left corner to make your first metadata record.
            </p>
          </div>
        </div>
      )}
      {!recordsQueryFinished && <WaveLoader />}
      {records.map((obj, idx) => {
        const handleDuplicateClick = (e) => {
          e.stopPropagation()
          duplicate(obj.tdisIdentifier, obj)
        }
        const handleDeleteClick = (e) => {
          e.stopPropagation()
          deleteRecord(obj.tdisIdentifier)
        }

        return (
          <div
            key={`card_${idx}_${obj.tdisIdentifier}`}
            style={{ textDecoration: 'none', cursor: 'pointer' }}
            onClick={() => navigate(getMetadataLink(obj))}
          >
            <Tile>
              <div>
                <div>
                  <TileHeader>{obj.currentMetadata.modelName}</TileHeader>
                  <TileText>{obj.currentMetadata.modelDescription}</TileText>
                  <TileText style={{ marginBottom: '0px' }}>
                    {obj.currentMetadata.city}
                  </TileText>
                  <TileText>{obj.currentMetadata.county}</TileText>
                </div>
                <div
                  style={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between',
                  }}
                >
                  <Dropdown
                    buttonContent={
                      <div
                        style={{
                          fontSize: '2em',
                          height: '10px',
                          position: 'relative',
                          top: '-20px',
                        }}
                      >
                        ...
                      </div>
                    }
                    dropdownId={`dropdown_${obj.tdisIdentifier}`}
                  >
                    <LinkLikeButton
                      style={{ display: 'block', padding: '3px 40px 3px 8px' }}
                      onClick={() => navigate(getMetadataLink(obj))}
                    >
                      {obj.statusInfo.status === 'in progress'
                        ? 'Edit'
                        : 'Edit'}
                    </LinkLikeButton>
                  </Dropdown>
                </div>
              </div>
            </Tile>
          </div>
        )
      })}
    </ListWrapper>
  )
}
