import React from 'react'
import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Provider as ReduxQueryProvider } from 'redux-query-react'
import store from './reducers/store'
import 'react-toastify/dist/ReactToastify.css'
import { Main } from './components/Main'
import ScrollToTop from './components/ScrollToTop'

const Router = () => {
  return (
    <BrowserRouter basename={'/'}>
      <ScrollToTop>
        <Routes>
          {/* Main application routes */}
          <Route path="/app/*" element={<Main/>} />
          {/* Catch-all redirect */}
          <Route path='*' element={<Navigate to="/app" replace />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  )
}

const App = () => (
  <Provider store={store}>
    <ReduxQueryProvider queriesSelector={(state) => state.queries}>
      <Router />
    </ReduxQueryProvider>
  </Provider>
)

export default App
