import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { clearStore } from '../actions/general'
import { USER_STORAGE_KEY } from '../constants/general'
import { useKeycloak } from '@react-keycloak/web'
import { useCurrentUser } from '../hooks/useCurrentUser'

export const useLogout = () => {
  const { keycloak } = useKeycloak() // Access Keycloak instance
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return async () => {
    try {
      // Clear local storage and Redux store
      localStorage.clear()
      dispatch(clearStore())

      // Call Keycloak logout with redirect to login page with `prompt=login`
      await keycloak.logout({
        redirectUri: `${window.location.origin}/login?prompt=login`,
      })

      // Notify user
      toast.info('Logged out successfully')
    } catch (error) {
      console.error('Failed to logout:', error)
      toast.error('Failed to logout. Please try again.')
    }
  }
}
