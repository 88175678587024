import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { ERROR, FOSSIL, ICE, MAIN_CONTENT_WIDTH, NOTICE, PRIMARY, SAGE, SHADOW_CRISP, SLATE } from '../constants/cssVars';
import { useNavigate } from 'react-router-dom';
import { useSelectedRecord } from '../hooks/useSelectedRecord';
import { LinkLikeButton } from './Button';
import { metadataFieldInfo } from '../constants/metadataFields';
import { useDispatch, useSelector } from 'react-redux';
import { fileStructureSelector, notificationsByIdSelector } from '../selectors/entities';
import { setHistoryModal } from '../actions/modals';
import { getFileStructureQuery, getNotificationsQuery, getRecordQuery } from '../actions/queries';
import { useRequest } from 'redux-query-react';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { QUERY_REFRESH_INTERVAL_MS } from '../constants/general';
import { useInterval } from '../hooks/useInterval';
import { capitalizeFirstLetter } from '../utils/helpers';
import { UploadConflictResolver } from './UploadConflictResolver';
import { Messages } from './Messages';
import { FileViewer } from './FileViewer';
import { SimpleContactDisplay } from './Contacts';
import { UploadedFilesHeader } from './UploadedFilesHeader';


const Wrapper = styled.div`
  padding-top: 20px;
  width: ${MAIN_CONTENT_WIDTH}px;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    width: 100%;
  }
`;

const BackButton = styled.button`
  background-color: transparent;
  border: none;
  > img {
    width: 40px;
    height: 30px;
  }
`

const Tile = styled.div`
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: ${SHADOW_CRISP};
  border: 1px solid ${FOSSIL};
  text-align: left;
  padding: 20px;
`

const FileViewerWrapper = styled.div`
  background-color: ${ICE};
  padding: 20px;
  border-radius: 4px;
`

const MetadataWrapper = styled.div`
  > div {
    padding: 5px;
  }
  > div:nth-child(even) {
    background-color: ${ICE};
  }
`

const MetadataRow = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    max-width: 50%;
  }
  > div:last-child {
    text-align: right;
    word-break: ${({ breakWords }) => breakWords ? 'break-all' : 'normal'};
    max-height: 250px;
    white-space: pre-wrap;
    overflow: auto;
  }
`

const getFriendlyName = (name) => metadataFieldInfo[name] ? metadataFieldInfo[name].friendlyName : capitalizeFirstLetter(name)

const statusColor = {
  'pending': NOTICE,
  'rejected': ERROR,
  'accepted': SAGE,
  'in progress': SLATE,
}

const StatusCard = styled.div`
  border-radius: 40px;
  padding: 2px 14px;
  display: inline-block;
  background-color: ${({status}) => statusColor[status]};
  color: #fff;
  font-size: 1em;
`


const Icon = styled.svg.attrs({ 
  version: '1.1', 
  xmlns: 'http://www.w3.org/2000/svg', 
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})``

const Svg = styled(Icon)` 
  width: 30px; 
  height: 30px; 
  &:hover {
    > path {
      fill: ${SAGE};
    }
  }
`

const BackIcon = () => {
  return (
    <Svg viewBox="0 0 115 115">
      <path d="M38.1421358,0.372624131 L46.627417,8.85786438 C47.0179413,9.24838867 47.0179413,9.88155365 46.627417,10.2720779 L26.8986242,29.9990928 L99,30 C99.5128358,30 99.9355072,30.3860402 99.9932723,30.8833789 L100,31 L100,43 C100,43.5522847 99.5522847,44 99,44 L99,44 L26.8986242,43.9990928 L46.627417,63.7279221 C46.987901,64.088406 47.0156305,64.6556371 46.7106056,65.0479283 L46.627417,65.1421356 L38.1376869,73.6318657 C37.7489043,74.0206483 37.1191787,74.0226355 36.7279501,73.6363143 L36.7279501,73.6363143 L0.645002969,38.0059706 C0.630873553,37.9920184 0.617162847,37.9776485 0.603889088,37.9628799 C0.234706253,37.5521215 0.268409924,36.9198542 0.679168344,36.5506713 L0.679168344,36.5506713 L4.40062418,33.2050928 L36.7279632,0.38338922 C37.1155035,-0.0100964547 37.7486501,-0.0149161489 38.1421358,0.372624131 Z" 
      id="arrow" fill={PRIMARY}></path>
    </Svg>
  )
}

const StatusArea = styled.div`
  background-color: ${NOTICE};
  padding: 10px;
  border-radius: 2px;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`

// Given the current status, find the notification object that corresponds to it
// so we can reference the user who updated the status and content
const getStatusNotification = (notifications, currentStatus) => {
  let matchingNotification = {};
  // Assuming most recent notification is at the end of the notifications array (we want most recent)
  notifications.forEach(notification => {
    if (notification.status === currentStatus) {
      matchingNotification = notification;
    }
  })
  return matchingNotification;
}

// View to show for a model that has been successfully uploaded
export const UploadedView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { accessToken } = useCurrentUser();
  const [showMetadata, setShowMetadata] = useState(false);
  const [selectedRecord, recordId] = useSelectedRecord();
  const [{ isFinished }] = useRequest(accessToken ? getFileStructureQuery(recordId, accessToken) : null)
  const [{ isFinished: getRequestFinished }, refresh] = useRequest(accessToken ? getRecordQuery(recordId, accessToken) : null);
  useRequest(accessToken ? getNotificationsQuery(recordId, accessToken) : null);
  const { fileStructure, unstructuredFiles } = useSelector(state => fileStructureSelector(state, recordId)) || {};
  const notificationsById = useSelector(notificationsByIdSelector);
  const notifications = notificationsById[recordId] || [];

  // Occassionally refresh our record (for things like status updates)
  useInterval(() => {
    refresh();
  }, QUERY_REFRESH_INTERVAL_MS);

  useEffect(() => {
    if (!selectedRecord && getRequestFinished) {
      navigate('/app')
    }
  }, [selectedRecord, getRequestFinished])

  if (!selectedRecord) {
    return null;
  }

  const status = selectedRecord.statusInfo.status;
  const { content: statusNotifContent, username: statusNotifUsername } = getStatusNotification(notifications, status);
  
  return (
    <Wrapper>
      <BackButton onClick={() => navigate('/app')} ariaLabel="back"><BackIcon alt="back arrow" /></BackButton>
      <h2 style={{marginLeft: '10px', marginTop: '10px'}}>{selectedRecord.currentMetadata.modelName}</h2>
      <UploadConflictResolver recordId={recordId} />
      <Tile>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px', alignItems: 'flex-start' }}>
          <h3 style={{marginTop: '0px'}}>Status & Messages</h3>
          <StatusCard status={status}>{status === 'pending' ? 'pending admin acceptance' : status}</StatusCard>
        </div>
        <div>
          {status === 'pending' && <StatusArea>This model has been uploaded and is awaiting an accept/reject decision from a TWDB admin.</StatusArea>}
          {status === 'in progress' && <StatusArea style={{ backgroundColor: '#ADC9DC' }}>This model has not yet been uploaded.</StatusArea>}
          {status === 'accepted' && <StatusArea style={{ backgroundColor: '#A5D6D9' }}>This model was accepted by a TWDB admin on {moment(selectedRecord.statusInfo.timeOfLastStatusChange).format('MMM Do, YYYY')}.</StatusArea>}
          {status === 'rejected' && <StatusArea style={{ backgroundColor: '#ECC6C6', color: '#8A1919', border: `1px solid ${ERROR}` }}>
            This model was rejected by a TWDB admin {statusNotifUsername ? `(${statusNotifUsername})` : ''} on {moment(selectedRecord.statusInfo.timeOfLastStatusChange).format('MMM Do, YYYY')}.
            {statusNotifContent ? <div style={{marginTop: '10px', whiteSpace: 'pre-wrap', overflowWrap: 'anywhere'}}>{statusNotifContent}</div> : ''}
          </StatusArea>}
          <Messages recordId={recordId} />
        </div>
      </Tile>
      <Tile>
        <UploadedFilesHeader />
        <FileViewerWrapper>
          {fileStructure && Object.keys(fileStructure).length > 0 ? Object.keys(fileStructure).map((name, idx) => {
            return <FileViewer unstructuredFiles={unstructuredFiles} parent={fileStructure} path={name} name={name} id={`fs${idx}`} key={`fs${idx}`} />
          }) : <div style={{ textAlign: 'center', fontStyle: 'italic'}}>No file structure found</div>}
        </FileViewerWrapper>
      </Tile>
      <Tile>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px', alignItems: 'flex-start' }}>
          <h3 style={{ marginTop: '0px'}}>Metadata</h3>
          <LinkLikeButton onClick={() => dispatch(setHistoryModal(true))}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              View History
            </div>
          </LinkLikeButton>
        </div>
        <div style={{ maxHeight: showMetadata ? 'none' : '200px', overflow: 'hidden' }}>
          <MetadataWrapper>
            {Object.keys(selectedRecord.currentMetadata).map(key => {
              let currentValue = selectedRecord.currentMetadata[key];
              if (key === 'timePeriodCovered') {
                currentValue = selectedRecord.currentMetadata[key].map(dateArr => dateArr.map(date => moment(date).format('YYYY-MM-DD')));
                currentValue = `[${currentValue.join(', ')}]`
              }
              if (key === 'modelCreationDate' || key === 'modelLastUpdatedDate') {
                currentValue = moment(currentValue).format('YYYY-MM-DD')
              }
              if (key === 'contacts') {
                currentValue = <div>{currentValue.map(([contactId, contactRoleId], idx) => {
                  return <SimpleContactDisplay key={`contact_${idx}`} contactId={contactId} contactRoleId={contactRoleId} />
                })}</div>
              }
              return (
                <MetadataRow key={key} breakWords={key === 'spatialExtentShapefile'} bold={key === 'modelName'}>
                  <div>{getFriendlyName(key)}</div>
                  <div>{Array.isArray(currentValue) ? currentValue.join(', ') : currentValue}</div>
                </MetadataRow>
              )})}
          </MetadataWrapper>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px'}}>
          <LinkLikeButton onClick={() => setShowMetadata(!showMetadata)}>
            Show {showMetadata ? 'less' : 'more'}
          </LinkLikeButton>
        </div>
      </Tile>
      <div style={{width: '100%', height: '120px'}}> </div>
    </Wrapper>
  )
}